$purpleBuddy: #661aff;
$lightPurpleBuddy: #f1e7ff;
$instaRed: #FF3E3E;
$instaPalePink: #FFE6E6;

.pb-selector-group {
  padding: 10px;
  max-width: 300px;
  margin-bottom: 20px;
  page-break-inside: avoid;
  break-inside: avoid;
  border-radius: 5px;
  border: 1px solid $purpleBuddy;
  list-style: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 0 1px $lightPurpleBuddy, 0 7px 14px 0 $lightPurpleBuddy,
      0 3px 6px 0 $lightPurpleBuddy;
    transition: box-shadow 0.3s;
  }

  .selector-title {
    margin-bottom: 10px;
  }
}

.ibx-selector-group {
  padding: 10px;
  max-width: 300px;
  margin-bottom: 20px;
  page-break-inside: avoid;
  break-inside: avoid;
  border-radius: 5px;
  border: 1px solid $instaRed;
  list-style: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 0 1px $instaPalePink, 0 7px 14px 0 $instaPalePink,
      0 3px 6px 0 $instaPalePink;
    transition: box-shadow 0.3s;
  }

  .selector-title {
    margin-bottom: 10px;
  }
}