$pbPurple: #661aff;
$pbLightPurple: #f1e7ff;
$instaRed: #FF3E3E;
$instaPink: #FFA2A2;

.order-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 16px;

}

.pb-order-card {
  border: 1px solid $pbPurple;
  background-color: $pbLightPurple;
  color: $pbPurple;
}

.ibx-order-card {
  border: 1px solid $instaRed;
  background-color: $instaPink;
  color: white;
}

.card-row {
  display: flex;
  flex-direction: row;
  width: 100%;

}

.card-column {
  display: flex;
  flex-direction: column;
}

.left-column {
  align-items: flex-start;
}

.center-column {
  margin-left: 15px;
  flex: 1;
}

.right-column {
  align-items: flex-end;
}

.card-header {
  font-weight: bold;
  margin-top: 5px;
}

.card-content {
  margin-top: 5px;
}

.card-action-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-action-text {
  font-size: 10px;
}