.sign-in-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .sign-in-modal__content {
    width: 460px;
    min-height: 260px;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #661aff;

    .sign-in {
      border-radius: 5px;
      font-size: 15px;
      padding: 6px 9px;
      background-color: white;
      border: 1px solid#661aff;
      margin: 0;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        background-color: #661aff;
        color: white;
        transition: 0.3s all;
      }
    }

    .content__inner-content {
      width: 300px;
      margin: auto;
    }
  }
}
