$purpleBuddy: #661aff;
$instaRed: #FF3E3E;
$instaPalePink: #FFE6E6;



.search-bar-container {
  width: 100%;
  max-width: 1200px;
}

.controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.ibx-controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.search-bar-input {
  outline: none;
  height: 24px;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #CECECE;
}

.search-bar-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-bar-button {
  height: 24px;
  border-radius: 5px;
}

.search-bar-pb-clear-button {
  color: $purpleBuddy;
  background-color: white;
  border: 1px solid $purpleBuddy;

  &:hover {
    background-color: $purpleBuddy;
    color: white;
    transition: 0.3s all;
  }
}

.search-bar-ibx-clear-button {
  color: $instaRed;
  background-color: $instaPalePink;
  border: 2px solid $instaRed;

  &:hover {
    background-color: $instaRed;
    color: white;
    transition: 0.3s all;
  }
}

.code-span {
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
  font-size: 85%;
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
}

.search-box-label {
  font-weight: bold;
}

.search-box-url-text {
  color: #666;
}

.search-copy-to-clip-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px; 
}

.search-disabled {
  color: #888;
}