$black: #222;
$purpleBuddy: #661aff;
$mediumBuddy: #b38dff;
$lightPurpleBuddy: #f1e7ff;
$instaRed: #FF3E3E;
$instaMediumRed: #FF7474;
$instaLightRed: #FFADAD;
$instaPalePink: #FFE6E6;

.App {
  display: flex;
  flex-direction: column;

  .App-loading {
    z-index: 1;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;

    .loading__content {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .loader {
        border: 16px solid #f3f3f3;
        border-top: 16px solid #096574;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .App-header {
    min-height: 50px;
    padding: 10px 10px 5px 10px;

    .header-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: auto;

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }

    .App-title {
      display: flex;
      flex-direction: column;
    }

    .App-navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      right: 20px;
      top: 20px;
      text-align: right;

      .App-navbar__sign-out {
        width: fit-content;
        padding: 6px 9px;
        border-radius: 5px;
        font-size: 15px;
        cursor: pointer;
        transition: 0.3s all;
      }

      .app-navbar-pb-sign-out {
        color: $purpleBuddy;
        background-color: white;
        border: 1px solid $purpleBuddy;

        &:hover {
          background-color: $purpleBuddy;
          color: white;
          transition: 0.3s all;
        }
      }

      .app-navbar-ibx-sign-out {
        color: $instaRed;
        background-color: $instaPalePink;
        border: 2px solid white;

        &:hover {
          background-color: $instaRed;
          color: white;
          transition: 0.3s all;
        }
      }

    }
  }

  .app-pb-brand {
    background-color: $lightPurpleBuddy;
    color: $purpleBuddy;
  }

  .app-ibx-brand {
    background-color: $instaRed;
    color: white;
  }


  .nav-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-bottom: 20px;
    box-shadow: 0 4px 2px -2px #00000033;
    border-bottom: 1px solid $purpleBuddy;

    .nav-tab-wrapper {
      display: flex;
      flex-direction: row;
      padding: 5px 5px 0px 5px;
      width: 100%;
      max-width: 1200px;
      margin: auto;
    }
  
    .nav-tab-pb {
      margin-right: 25px;
      padding: 5px 5px 3px 5px;
      cursor: pointer;
      color: $black;
  
      &:hover {
        border-radius: 5px;
        color: $mediumBuddy;
        transition: all 0.3s;
      }
    }

    .nav-tab-ibx {
      margin-right: 25px;
      padding: 5px 5px 3px 5px;
      cursor: pointer;
      color: $instaLightRed;
  
      &:hover {
        border-radius: 5px;
        color: $instaPalePink;
        transition: all 0.3s;
      }
    }
  
    .nav-tab-active-pb {
      border-bottom: 3px solid $purpleBuddy;
      color: $purpleBuddy;
    }

    .nav-tab-active-ibx {
      border-bottom: 3px solid white;
      color: white;
    }
  }

  .App-content {
    margin: 0px auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    .settings {
      grid-column-gap: 12px;
      column-gap: 12px;
      column-fill: balance;
      flex-direction: column;
      min-height: max-content;
      margin: 30px 0;

      @media (min-width: 768px) {
        columns: 3 auto;
      }

      @media (min-width: 1024px) {
        columns: 4 auto;
      }

      &-list {
        padding: 0 20px;
        margin: 0;
      }
    }

    .pb-generate-btn {
      width: 100%;
      font-size: 18px;
      padding: 6px 3px;
      margin-bottom: 10px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid $purpleBuddy;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $purpleBuddy;
        color: white;
        transition: all 0.3s;
      }
    }
    .ibx-generate-btn {
      width: 100%;
      font-size: 18px;
      padding: 6px 3px;
      margin-bottom: 10px;
      background-color: $instaPalePink;
      border-radius: 5px;
      border: 1px solid $instaRed;
      color: $instaRed;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $instaRed;
        color: white;
        transition: all 0.3s;
      }
    }
    .clear-orders-btn {
      font-size: 18px;
      margin-left: 5px;
      padding: 6px 12px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid $black;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $instaRed;
        color: white;
        transition: all 0.3s;
      }
    }
  }

  .ButtonGroup__TimeWindow {
    .button {
      border-radius: 5px;
      font-size: 14px;
      padding: 2px 6px;
      margin-bottom: 5px;
      background-color: white;
      border: 1px solid #cccccc;
      cursor: pointer;
    }
  }

  .button-group {
    .button {
      border-radius: 0;
      border-right-width: 0;
      margin: 0px;
      font-size: 14px;
      padding: 2px 6px;
      background-color: white;
      border: 1px solid #cccccc;
      cursor: pointer;

      &:first-child {
        border-radius: 5px 0 0 5px;
        border-width: 1px 0px 1px 1px;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
        border-width: 1px 1px 1px 0;
      }
    }
  }

  .inspect-content {
    margin: 0px auto;
    min-width: 800px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .inspect-controls-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
